<template>
  <div class="login" :style="{ height: bhei }">
    <el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm"
      style="margin: 220px auto">
      <!-- <el-form-item label="用户名" prop="user">
        <el-input v-model.number="ruleForm2.user" style="width:260px"></el-input>
      </el-form-item> -->
      <el-form-item label="新密码" prop="pass">
        <el-input type="password" v-model="ruleForm2.pass" auto-complete="off" style="width: 260px"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="pass2">
        <el-input type="password" v-model="ruleForm2.pass2" auto-complete="off" style="width: 260px"></el-input>
      </el-form-item>
      <el-row type="flex" class="row-bg" justify="center" style="margin-top: 40px; margin-left: -15px">
        <el-button type="primary" @click="submitForm('ruleForm2')">提交修改</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名不能为空"));
        return false;
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
        return false;
      } else {
        if (this.ruleForm2.checkPass !== "") {
          this.$refs.ruleForm2.validateField("checkPass");
        }
        callback();
      }
    };
    var checkcode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请再次输入新密码"));
        return false;
      }
      callback();
    };
    return {
      ruleForm2: {
        pass: "",
        user: "",
        pass2: "",
      },
      rules2: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        user: [{ validator: checkAge, trigger: "blur" }],
        pass2: [{ validator: checkcode, trigger: "blur" }],
      },
      bhei: "",
      admin_id: "",
    };
  },
  created() {
    // this.getcode();
    this.admin_id = this.$route.params.admin;
    this.bhei = this.util.gaodu() + "px";
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm2.pass !== this.ruleForm2.pass2) {
            this.$message({
              showClose: true,
              type: "error",
              message: "密码输入不一致!",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
          this.$loading({
            fullscreen: true,
            background: "rgba(0, 0, 0, 0.7)",
            text: "提交中...",
          });
          let that = this;
          that.util
            .post(that.HOST + "/Login/modify  ", {
              username: that.ruleForm2.user,
              new_password: that.ruleForm2.pass2,
              sure_password: that.ruleForm2.pass2,
              admin_id: that.admin_id,
            })
            .then((res) => {
              if (res.code == 200) {
                this.$loading().close();
                this.$message({
                  showClose: true,
                  message: "密码修改成功!",
                  type: "success",
                  duration: 1500,
                  offset: 90,
                });
                sessionStorage.clear();
                setTimeout(() => {
                  this.$router.push({ path: "/" });
                }, 1500);
              } else {
                that.$message({
                  showClose: true,
                  type: "error",
                  message: res.msg,
                  duration: 1500,
                  offset: 90,
                });
                this.$loading().close();
              }
            });
        } else {
          window.console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.el-form-item--feedback {
  width: 500px;
  margin: 30px auto;
}

.el-form-item__label {
  color: white !important;
}

.yanzheng {
  width: 120px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.el-form-item__content {
  display: flex;
  margin-left: 0 !important;
}

.login {
  /* background: url("../assets/images/background.jpg") no-repeat; */
  background: url('https://imgaes.yiniaoweb.com/cc/background.5743a3a5.jpg') no-repeat;
  background-size: 100%;
  height: 100%;
  margin-top: 0;
  display: flex;
}
</style>

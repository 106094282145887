var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticStyle:{"position":"fixed","width":"100%","z-index":"999","top":"0"}},[_c('Header')],1),_c('div',{staticClass:"flex_start"},[_c('el-menu',[_c('div',{staticStyle:{"display":"flex","position":"fixed","top":"60px","height":"100%"}},[_c('div',{staticClass:"menu1"},[_c('div',{staticClass:"changeBg leftMenuChild",class:[_vm.index == 1 ? 'selectedTitle' : ''],attrs:{"index":1},on:{"click":_vm.firstTitle}},[_vm._v(" 工作台 ")]),(
              _vm.administrators == 1 ||
              _vm.administrators == 2 ||
              _vm.administrators == 4
            )?_c('div',{staticClass:"changeBg leftMenuChild",class:[_vm.index == 2 ? 'selectedTitle' : ''],attrs:{"index":2},on:{"click":_vm.secondTitle}},[_vm._v(" 订单查询 ")]):_vm._e(),(_vm.administrators == 1 || _vm.administrators == 4)?_c('div',{staticClass:"changeBg leftMenuChild",class:[_vm.index == 3 ? 'selectedTitle' : ''],attrs:{"index":3},on:{"click":_vm.thirdTitle}},[_vm._v(" 任务分配 ")]):_vm._e(),(_vm.administrators == 1 || _vm.administrators == 4)?_c('div',{staticClass:"changeBg leftMenuChild",class:[_vm.index == 4 ? 'selectedTitle' : ''],attrs:{"index":4},on:{"click":_vm.fourthTitle}},[_vm._v(" 角色管理 ")]):_vm._e()]),_c('div',{staticClass:"menu2"},[(_vm.index == 1 && _vm.newOrder != 0 && _vm.administrators != 3)?_c('div',{staticClass:"abs hotPr",staticStyle:{"top":"77px"}},[_vm._v(" "+_vm._s(_vm.newOrder)+" ")]):_vm._e(),(_vm.index == 1 && _vm.pendOrder != 0 && _vm.administrators != 3)?_c('div',{staticClass:"abs hotPr",staticStyle:{"top":"126px"}},[_vm._v(" "+_vm._s(_vm.pendOrder)+" ")]):_vm._e(),(_vm.index == 1 && _vm.hangOrder != 0 && _vm.administrators != 3)?_c('div',{staticClass:"abs hotPr",staticStyle:{"top":"174px"}},[_vm._v(" "+_vm._s(_vm.hangOrder)+" ")]):_vm._e(),(_vm.index == 1)?_c('div',[_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 1 ? 'selectedChild' : ''],attrs:{"child":1},on:{"click":_vm.firstChild}},[_vm._v(" 首页 ")]),(
                _vm.administrators == 1 ||
                _vm.administrators == 2 ||
                _vm.administrators == 4
              )?_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 2 ? 'selectedChild' : ''],attrs:{"child":2},on:{"click":_vm.secondChild}},[_vm._v(" 订单指派 ")]):_vm._e(),(
                _vm.administrators == 1 ||
                _vm.administrators == 2 ||
                _vm.administrators == 4
              )?_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 3 ? 'selectedChild' : ''],attrs:{"child":3},on:{"click":_vm.thirdChild}},[_vm._v(" 待处理任务 ")]):_vm._e(),(
                _vm.administrators == 1 ||
                _vm.administrators == 2 ||
                _vm.administrators == 4
              )?_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 4 ? 'selectedChild' : ''],attrs:{"child":4},on:{"click":_vm.fourthChild}},[_vm._v(" 挂起的任务 ")]):_vm._e(),(
                _vm.administrators == 1 ||
                _vm.administrators == 2 ||
                _vm.administrators == 4
              )?_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 5 ? 'selectedChild' : ''],attrs:{"child":5},on:{"click":_vm.fifthChild}},[_vm._v(" 已处理任务 ")]):_vm._e(),(
                _vm.administrators == 1 ||
                _vm.administrators == 2 ||
                _vm.administrators == 3 ||
                _vm.administrators == 4
              )?_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 6 ? 'selectedChild' : ''],attrs:{"child":6},on:{"click":_vm.sixChild}},[_vm._v(" 商机池列表 ")]):_vm._e(),(
                _vm.administrators == 1 ||
                _vm.administrators == 2 ||
                _vm.administrators == 4
              )?_c('div',{staticClass:"smallTit canClick",class:[_vm.child == 8 ? 'selectedChild' : ''],attrs:{"child":8},on:{"click":_vm.eightChild}},[_vm._v(" 标品预约信息 ")]):_vm._e()]):_vm._e(),(_vm.index == 2)?_c('div',[_c('div',{staticClass:"selectedChild"},[_vm._v("订单查询")])]):_vm._e(),(_vm.index == 3)?_c('div',[_c('div',{staticClass:"selectedChild"},[_vm._v("任务分配")])]):_vm._e(),(_vm.index == 4)?_c('div',[_c('div',{staticClass:"selectedChild"},[_vm._v("角色管理")])]):_vm._e()])])]),_c('el-main',{staticStyle:{"margin-left":"169px"}},[_c('div',{staticClass:"rmain",style:({ height: _vm.winheight })},[(_vm.pagename == 'first')?_c('First'):_vm._e(),(_vm.pagename == 'orderfinding')?_c('Orderfinding'):_vm._e(),(_vm.pagename == 'rolemanagement')?_c('Rolemanagement'):_vm._e(),(_vm.pagename == 'taskallocation')?_c('Taskallocation'):_vm._e(),(_vm.pagename == 'dealOrder')?_c('DealOrder'):_vm._e(),(_vm.pagename == 'firstPage')?_c('FirstPage',{ref:"orderPool"}):_vm._e(),(_vm.pagename == 'pendTask')?_c('PendTask'):_vm._e(),(_vm.pagename == 'hangTask')?_c('HangTask'):_vm._e(),(_vm.pagename == 'dealedTask')?_c('DealedTask'):_vm._e(),(_vm.pagename == 'basicPlan')?_c('BasicPlan'):_vm._e(),(_vm.pagename == 'checkPlan')?_c('CheckPlan'):_vm._e(),(_vm.pagename == 'orderPool')?_c('OrderPool'):_vm._e(),(_vm.pagename == 'standardInfo')?_c('standardInfo'):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <div id="container"></div>
  </section>
</template>

<script>
export default {
  name: "mk-map",
  data() {
    return {
      address: '暂无数据',
      currentTime: '暂无数据',
      lat: '',
      lng: '',
    }
  },
  mounted() {
    // 获取当前经纬度
    this.getMapLocation(this.$route.query.userId)
  },
  methods: {
    // 获取当前轨迹经纬度方法
    getMapLocation(userId) {
      this.util
        .get(this.HOST + "/User/userActive", {
          user_id: userId,
          // user_id: 241000241000304,
        })
        .then((res) => {
          console.log('当前数据', res);
          if (res.code == 200 && res.data) {
            this.address = res.data.address
            this.currentTime = this.util.timestampToTime(res.data.time)
            this.lat = res.data.lat
            this.lng = res.data.lng
          }
          // 初始化地图
          this.initMap()
        });
    },

    //初始化地图方法
    initMap() {
      let that = this
      var center = new TMap.LatLng(that.lat, that.lng);//设置中心点坐标
      //初始化地图
      var map = new TMap.Map("container", {
        zoom: 15,
        center: center
      });

      //初始marker
      var marker = new TMap.MultiMarker({
        id: 'marker-layer',
        map: map,
        styles: {
          "marker": new TMap.MarkerStyle({
            "width": 25,
            "height": 35,
            "anchor": { x: 12, y: 35 },
          })
        },
        geometries: [{
          "id": 'demo1',
          "styleId": 'marker',
          "position": new TMap.LatLng(that.lat, that.lng),
          "properties": {
            "title": "marker"
          }
        }]
      });
      //初始化infoWindow 信息弹窗
      var infoWindow = new TMap.InfoWindow({
        map: map,
        position: new TMap.LatLng(that.lat, that.lng),
        offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
      });
      infoWindow.setContent(`<div style="font-size:14px;text-align:left">最后位置: ${that.address}</div> <div style="font-size:14px;text-align:left">最后时间: ${that.currentTime}</div>`);//设置信息窗内容
      // infoWindow.close();//初始关闭信息窗关闭
      //监听标注点击事件
      marker.on("click", function (evt) {
        //设置infoWindow
        infoWindow.open(); //打开信息窗
        infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
        infoWindow.setContent(`<div style="font-size:14px;text-align:left">最后位置: ${that.address}</div> <div style="font-size:14px;text-align:left">最后时间: ${that.currentTime}</div>`);//设置信息窗内容
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  height: calc(100vh);
  border-radius: 30px;
  overflow: hidden;
}
</style>

<template>
  <div class="home">
    <div style="position: fixed; width: 100%; z-index: 999; top: 0">
      <!-- <img src="../assets/images/right.png" alt class="opclo" @click="zhankai" /> -->
      <Header></Header>
    </div>
    <div class="flex_start">
      <el-menu>
        <div style="display: flex; position: fixed; top: 60px; height: 100%">
          <div class="menu1">
            <div
              :index="1"
              @click="firstTitle"
              :class="[index == 1 ? 'selectedTitle' : '']"
              class="changeBg leftMenuChild"
            >
              工作台
            </div>
            <div
              :index="2"
              @click="secondTitle"
              :class="[index == 2 ? 'selectedTitle' : '']"
              class="changeBg leftMenuChild"
              v-if="
                administrators == 1 ||
                administrators == 2 ||
                administrators == 4
              "
            >
              订单查询
            </div>
            <div
              :index="3"
              @click="thirdTitle"
              :class="[index == 3 ? 'selectedTitle' : '']"
              class="changeBg leftMenuChild"
              v-if="administrators == 1 || administrators == 4"
            >
              任务分配
            </div>
            <div
              :index="4"
              @click="fourthTitle"
              :class="[index == 4 ? 'selectedTitle' : '']"
              class="changeBg leftMenuChild"
              v-if="administrators == 1 || administrators == 4"
            >
              角色管理
            </div>
          </div>
          <div class="menu2">
            <div
              style="top: 77px"
              class="abs hotPr"
              v-if="index == 1 && newOrder != 0 && administrators != 3"
            >
              {{ newOrder }}
            </div>
            <div
              style="top: 126px"
              class="abs hotPr"
              v-if="index == 1 && pendOrder != 0 && administrators != 3"
            >
              {{ pendOrder }}
            </div>
            <div
              style="top: 174px"
              class="abs hotPr"
              v-if="index == 1 && hangOrder != 0 && administrators != 3"
            >
              {{ hangOrder }}
            </div>
            <div v-if="index == 1">
              <div
                :child="1"
                @click="firstChild"
                :class="[child == 1 ? 'selectedChild' : '']"
                class="smallTit canClick"
              >
                首页
              </div>
              <div
                :child="2"
                @click="secondChild"
                :class="[child == 2 ? 'selectedChild' : '']"
                class="smallTit canClick"
                v-if="
                  administrators == 1 ||
                  administrators == 2 ||
                  administrators == 4
                "
              >
                订单指派
              </div>
              <div
                :child="3"
                @click="thirdChild"
                :class="[child == 3 ? 'selectedChild' : '']"
                class="smallTit canClick"
                v-if="
                  administrators == 1 ||
                  administrators == 2 ||
                  administrators == 4
                "
              >
                待处理任务
              </div>
              <div
                :child="4"
                @click="fourthChild"
                :class="[child == 4 ? 'selectedChild' : '']"
                class="smallTit canClick"
                v-if="
                  administrators == 1 ||
                  administrators == 2 ||
                  administrators == 4
                "
              >
                挂起的任务
              </div>
              <div
                :child="5"
                @click="fifthChild"
                :class="[child == 5 ? 'selectedChild' : '']"
                class="smallTit canClick"
                v-if="
                  administrators == 1 ||
                  administrators == 2 ||
                  administrators == 4
                "
              >
                已处理任务
              </div>
              <!-- <div
                :child="5"
                @click="sixChild"
                :class="[child == 6 ? 'selectedChild' : '']"
                class="smallTit canClick"
              >
                商机池
              </div> -->

              <div
                :child="6"
                @click="sixChild"
                :class="[child == 6 ? 'selectedChild' : '']"
                class="smallTit canClick"
                v-if="
                  administrators == 1 ||
                  administrators == 2 ||
                  administrators == 3 ||
                  administrators == 4
                "
              >
                商机池列表
              </div>

              <!-- 标品预约信息 -->
              <div
                :child="8"
                @click="eightChild"
                :class="[child == 8 ? 'selectedChild' : '']"
                class="smallTit canClick"
                v-if="
                  administrators == 1 ||
                  administrators == 2 ||
                  administrators == 4
                "
              >
                标品预约信息
              </div>
            </div>
            <div v-if="index == 2">
              <div class="selectedChild">订单查询</div>
            </div>
            <div v-if="index == 3">
              <div class="selectedChild">任务分配</div>
            </div>
            <div v-if="index == 4">
              <div class="selectedChild">角色管理</div>
            </div>
            <!-- <div v-if="index == 5">
              <div :plan="1" @click="firstPlan" :class="[plan == 1 ? 'selectedChild' : '']" class="smallTit canClick">
                基础方案列表
              </div>
              <div :plan="2" @click="secondPlan" :class="[plan == 2 ? 'selectedChild' : '']" class="smallTit canClick">
                基检结果列表
              </div>
            </div> -->
          </div>
        </div>
      </el-menu>
      <el-main style="margin-left: 169px">
        <div class="rmain" :style="{ height: winheight }">
          <First v-if="pagename == 'first'"></First>
          <Orderfinding v-if="pagename == 'orderfinding'"></Orderfinding>
          <Rolemanagement v-if="pagename == 'rolemanagement'"></Rolemanagement>
          <Taskallocation v-if="pagename == 'taskallocation'"></Taskallocation>
          <DealOrder v-if="pagename == 'dealOrder'"></DealOrder>
          <FirstPage v-if="pagename == 'firstPage'" ref="orderPool"></FirstPage>
          <PendTask v-if="pagename == 'pendTask'"></PendTask>
          <HangTask v-if="pagename == 'hangTask'"></HangTask>
          <DealedTask v-if="pagename == 'dealedTask'"></DealedTask>
          <BasicPlan v-if="pagename == 'basicPlan'"></BasicPlan>
          <CheckPlan v-if="pagename == 'checkPlan'"></CheckPlan>
          <!-- 有效商机池 -->
          <OrderPool v-if="pagename == 'orderPool'"></OrderPool>
          <!-- 一般商机池 -->
          <!-- <commonOrderPool v-if="pagename == 'commonOrderPool'"></commonOrderPool> -->

          <!-- 标品预约信息 -->
          <standardInfo v-if="pagename == 'standardInfo'"></standardInfo>
        </div>
      </el-main>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
let audio = null;
export default {
  name: "home",
  data() {
    return {
      isdiag: false,
      dialogTableVisibleadd: false, //查看订单详情弹窗
      business: "http://yiniao.oss-cn-shenzhen.aliyuncs.com/cc/business.mp3", //商机操作提示音
      assign: "http://yiniao.oss-cn-shenzhen.aliyuncs.com/cc/assign.mp3", //指派提示音
      mp3: "http://yiniao.oss-cn-shenzhen.aliyuncs.com/cc/15370.wav", //订单操作提示音
      isCollapse: true,
      // pagename: "first",
      pagename: "firstPage",
      imgUrl: this.util.imgUrl,
      works: 0,
      winheight: "",
      administrators: "",
      maxwidth: "",
      lwidth: "",
      notifyOrder: "",
      orderCount: 0,
      pendCount: 0,
      hangCount: 0,
      timer: "",
      index: 1,
      child: 1,
      plan: 1,
      count4: [], //待联系订单
      count5: [], //待联系订单
    };
  },
  created() {
    audio = new Audio();
    this.checkNewOrder();
    this.timer = setInterval(() => {
      if (sessionStorage.getItem("token")) {
        setTimeout(this.checkNewOrder, 0);
      }
      if (!sessionStorage.getItem("token")) {
        //清除定时器
        clearInterval(this.timer);
      }
    }, 3000);
  },
  mounted() {
    this.administrators = sessionStorage.getItem("administrators");
    // this.util.fenpei(this.HOST + "/Cc/AllocationRule", {}).then(res => {
    //   if (res.code == 200) {
    //     this.winheight = this.util.gaodu() - 81 + "px";
    //   }
    // });
  },
  methods: {
    diagclose() {
      //
    },
    diagopen() {},
    //播放音频 商机池
    playAudioBusiness() {
      audio.src = this.business;
      audio.play();
    },
    //播放音频 指派订单
    playAudioAssign() {
      audio.src = this.assign;
      audio.play();
    },
    //播放音频 订单操作
    playAudioMp3() {
      audio.src = this.mp3;
      audio.play();
    },
    //停止音频
    stopAudio() {
      // audio.pause();
    },
    firstTitle() {
      //点击第一个标题

      this.pagename = "firstPage";
      this.index = 1;
      this.child = 1;
    },
    secondTitle() {
      //点击第二个标题
      this.pagename = "orderfinding";
      this.index = 2;
    },
    thirdTitle() {
      //点击第三个标题
      this.pagename = "taskallocation";
      this.index = 3;
    },
    fourthTitle() {
      //点击第四个标题
      this.pagename = "rolemanagement";
      this.index = 4;
    },
    fifthTitle() {
      //点击第五个标题-方案库
      this.pagename = "basicPlan";
      this.index = 5;
      this.plan = 1;
    },
    firstChild() {
      if (this.$refs.orderPool) {
        this.$refs.orderPool.hide();
      }

      this.child = 1; //首页
      this.pagename = "firstPage";
    },
    secondChild() {
      this.child = 2; //订单指派
      this.pagename = "dealOrder";
    },
    thirdChild() {
      this.child = 3; //待处理任务
      this.pagename = "pendTask";
    },
    fourthChild() {
      this.child = 4; //挂起的任务
      this.pagename = "hangTask";
    },
    fifthChild() {
      this.child = 5; //已处理任务
      this.pagename = "dealedTask";
    },
    sixChild() {
      this.child = 6; //(有效商机池)
      this.pagename = "orderPool";
    },
    // sevenChild() {
    //   this.child = 7; //一般商机池
    //   this.pagename = "commonOrderPool";
    // },
    eightChild() {
      this.child = 8; //标品预约信息
      this.pagename = "standardInfo";
    },
    firstPlan() {
      //基础方案列表
      this.plan = 1;
      this.pagename = "basicPlan";
    },
    secondPlan() {
      //基检结果列表
      this.plan = 2;
      this.pagename = "checkPlan";
    },

    checkNewOrder() {
      //检查有无新订单
      this.util.get(this.HOST + "/User/polling", {}).then((res) => {
        const h = this.$createElement;
        if (res.code == 200) {
          // console.log("this.newOrder", this.newOrder);
          // console.log("res.data.count1", res.data.count1);
          // 最新的待指派比存入的待指派大 就提示弹框
          if (this.newOrder < res.data.count1) {
            this.$store.commit("setNewOrder", res.data.count1);
            this.orderNotify();
          }
          // 存入vuex
          this.$store.commit("setNewOrder", res.data.count1);
          this.$store.commit("setPendOrder", res.data.count2);
          this.$store.commit("setHangOrder", res.data.count3);
          if (res.data.count4.length > 0) {
            for (let i = 0; i < res.data.count4.length; i++) {
              if (!this.count4.includes(res.data.count4[i].order_ids)) {
                this.playAudioMp3();
                this.$notify({
                  title: "待联系订单",
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                  message: `<text>姓名:${res.data.count4[i].contacts}</text></br><text>电话:${res.data.count4[i].telephone}</text>`,
                  duration: 0,
                  position: "bottom-right",
                });
                this.count4.push(res.data.count4[i].order_ids);
              }
            }
          }
          if (res.data.count5.length > 0) {
            for (let i = 0; i < res.data.count5.length; i++) {
              if (!this.count5.includes(res.data.count5[i].through_id)) {
                this.playAudioBusiness();
                this.$notify({
                  title: "待联系商机",
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                  message: `<text>姓名:${res.data.count5[i].contacts}</text></br><text>电话:${res.data.count5[i].telephone}</text>`,
                  duration: 0,
                  position: "bottom-right",
                });
                this.count5.push(res.data.count5[i].through_id);
              }
            }
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    orderNotify() {
      this.playAudioAssign();
      this.notifyOrder = this.$notify({
        title: "警告",
        message: "有" + this.newOrder + "个订单未指派！",
        offset: 100,
        duration: 12000,
        type: "warning",
      });
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    menu(e, num) {
      this.pagename = num;
    },
    zhankai() {
      if (this.isCollapse == true) {
        this.isCollapse = false;
      } else {
        this.isCollapse = true;
      }
    },
  },
  beforeDestroy() {
    // this.notifyOrder.close()
    // clearInterval(this.timer);
  },
  computed: {
    getworks() {
      this.works = this.$store.state.works;
    },
    getwidth() {
      // this.lwidth = this.$store.state.llwidth;
      this.maxwidth =
        this.util.kuandu() - Number(this.$store.state.llwidth) - 26 + "px";
    },
    ...mapState({
      newOrder: (state) => state.newOrder,
      pendOrder: (state) => state.pendOrder,
      hangOrder: (state) => state.hangOrder,
      token: (state) => state.token,
    }),
  },
  watch: {
    // 未分配任务数
    getworks() {
      // //console.log(this.$store.state.task, "121212");
    },
    getwidth() {
      this.$nextTick(function () {
        this.$store.commit(
          "setlwidth",
          this.$refs.companyStyle.$el.clientWidth
        );
        // this.lwidth = this.$refs.companyStyle.$el.clientWidth;
      });
    },
  },
  components: {
    Header: () => import("@/components/header.vue"),
    First: () => import("@/components/first.vue"),
    Orderfinding: () => import("@/components/orderfinding.vue"),
    Rolemanagement: () => import("@/components/rolemanagement.vue"),
    Taskallocation: () => import("@/components/taskallocation.vue"),
    DealOrder: () => import("@/views/dealOrder.vue"),
    FirstPage: () => import("@/views/firstPage.vue"),
    PendTask: () => import("@/views/pendTask.vue"),
    HangTask: () => import("@/views/hangTask.vue"),
    DealedTask: () => import("@/views/dealedTask.vue"),
    OrderPool: () => import("@/views/orderPool.vue"), //有效商机池
    commonOrderPool: () => import("@/views/commonOrderPool.vue"), //一般商机池
    standardInfo: () => import("@/views/standardInfo.vue"), //标品预约信息
    BasicPlan: () => import("@/views/plan/basicPlan.vue"),
    CheckPlan: () => import("@/views/plan/checkPlan.vue"),
    Detailes2: () => import("@/components/detailes2.vue"),
  },
};
</script>
<style scoped>
.home {
  background: white;
  position: relative;
}

.menu {
  color: white;
  position: absolute;
  top: 10px;
  font-size: 30px;
  left: 10px;
  cursor: pointer;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu--collapse {
  min-width: 65px;
}

.leftmenu {
  margin-top: 60px;
  position: relative;
}

.leftMenuChild {
  font-size: 14px;
  cursor: pointer;
}

.opclo {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 19px;
  font-size: 30px;
  left: 26px;
  cursor: pointer;
}

.rmain {
  margin-top: 60px;
  min-width: 860px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 860px;
  background: white;
}

.redDot {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 70px;
  right: 7px;
}

.selectedTitle {
  color: rgb(255, 208, 75);
  background: #084370;
}

.selectedChild {
  font-weight: 700;
  font-size: 14px;
  color: #111;
  border-right: 2px solid #084370;
}

.el-main {
  padding: 2px !important;
}

.menu1 {
  width: 80px;
  background: #04223a;
  color: white;
  height: 100%;
  line-height: 70px;
  text-align: center;
  z-index: 999;
}

.menu2 {
  width: 100px;
  color: rgb(121, 119, 119);
  box-shadow: 4px 4px 10px #888888;
  height: 100%;
  padding-left: 4px;
  line-height: 40px;
  text-align: left;
  padding-top: 13px;
  z-index: 999;
}

.changeBg:hover {
  background: #084370;
}

.smallTit:hover {
  border-right: 2px solid #084370;
  color: black;
}

.canClick {
  cursor: pointer;
  font-size: 14px;
  margin: 8px 0;
}

.hotPr {
  min-width: 20px;
  height: 18px;
  line-height: 18px;
  background: red;
  color: white;
  font-size: 12px;
  padding-bottom: 5px;
  left: 156px;
  text-align: center;
  border-radius: 5px;
}
</style>

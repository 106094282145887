<template>
  <div class="orderdetails" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="p5">
      <el-tab-pane label="订单信息" name="first">订单信息</el-tab-pane>

      <el-tab-pane label="任务沟通处理记录" name="second"
        >任务处理记录</el-tab-pane
      >
      <el-tab-pane label="报价合同" name="third">报价合同</el-tab-pane>
      <el-tab-pane label="施工信息" name="fourth">施工信息</el-tab-pane>
      <el-tab-pane label="回访信息" name="five">回访信息</el-tab-pane>
    </el-tabs>
    <!-- 订单信息 -->
    <div
      v-if="activeName == 'first'"
      class="pb20"
      :style="{ display: activeName == 'first' ? 'block' : 'none' }"
    >
      <div class="flex_between pb10">
        <div class="title2 f-left p10 bbe9s">客户信息</div>
      </div>
      <div class="flex_warp p5">
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">客户姓名:</span>
            <span class="rsp">{{ infos2.contacts }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">联系方式:</span>
            <span class="rsp">{{ infos2.telephone }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">房屋问题:</span>
            <span v-if="infos2.problemTitle">{{ infos2.problemTitle }}</span>
            <span v-if="infos2.problemTitle1"
              >、{{ infos2.problemTitle1 }}</span
            >
            <span v-if="infos2.problemTitle2"
              >、{{ infos2.problemTitle2 }}</span
            >
          </div>
        </div>
        <div class="blocks flex_warp">
          <!-- <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">推荐姓名:</span>
            <span class="rsp">{{ infos2.management }}</span>
          </div> -->
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">订单来源:</span>
            <span class="rsp" v-if="infos2.channelTitle != null"
              >{{ infos2.channel }}-{{ infos2.channelTitle }}</span
            >
            <span class="rsp" v-else>{{ infos2.channel }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20" style="width: 50%">
            <span class="mr10 t1sp">客户地址:</span>
            <span class="rsp rsp2" style="width: 80%">{{ infos2.addres }}</span>
          </div>
        </div>
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">订单状态:</span>
            <span class="rsp">{{ infos2.states }}</span>
          </div>
          <!-- <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">所属物业:</span>
            <span class="rsp">{{ infos2.content }}</span>
          </div> -->
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">下单时间:</span>
            <span class="rsp">{{ infos2.created_time }}</span>
          </div>
        </div>
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">实际开工:</span>
            <span class="rsp">{{ infos2.start_time }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">实际完工:</span>
            <span class="rsp">{{ infos2.finish_time }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">完款时间:</span>
            <span class="rsp">{{ infos2.received_time }}</span>
          </div>
        </div>
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">小区地址:</span>
            <span class="rsp">{{ infos2.residential_quarters }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">录入人员:</span>
            <span class="rsp">{{ infos2.entry }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">结算时间:</span>
            <span class="rsp">{{ infos2.cleared_time }}</span>
          </div>
        </div>
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">质保卡状态:</span>
            <span class="rsp" v-if="infos2.warrantyCollection1 == 1"
              >未领取</span
            >
            <span class="rsp" v-if="infos2.warrantyCollection1 == 2"
              >已领取</span
            >
            <span class="rsp" v-else>没有质保</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">推客宝管家:</span>
            <span class="rsp">{{ infos2.personalUsername }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">添加微信:</span>
            <el-switch
              v-model="infos2.wechat"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changewechat(infos2.order_id, infos2.wechat)"
            ></el-switch>
          </div>
        </div>
      </div>

      <div class="blocks flex_warp">
        <div class="flex_start flex_items mt20 p5">
          <span class="mr10 t1sp">是否精装房:</span>
          <div class="rsp" v-if="infos2.hardbound == 1">是</div>
          <div class="rsp" v-else>否</div>
        </div>

        <div class="flex_start flex_items mt20 p5">
          <span class="mr10 t1sp">希望上门时间:</span>
          <div class="rsp" v-if="infos2.visiting_time != 0">
            {{
              infos2.visiting_time == 1
                ? "立即"
                : infos2.visiting_time == 2
                ? "三天内"
                : infos2.visiting_time == 3
                ? "七天内"
                : "不着急"
            }}
          </div>
          <div class="rsp" v-else>未选择</div>
        </div>
        <div class="flex_start flex_items mt20 p5">
          <span class="mr10 t1sp">指派类型:</span>
          <div class="rsp">
            {{
              infos2.dispatchType == 1
                ? "直派用户"
                : infos2.dispatchType == 0
                ? "未选择"
                : "咨询用户"
            }}
          </div>
        </div>
      </div>

      <div v-if="infos2.reasons != null" class="flex_start flex_items mt20 p5">
        <span class="mr10 t1sp">取消原因:</span>
        <div class="rsp" style="width: 800px">{{ infos2.reasons }}</div>
      </div>
      <div class="flex_start flex_items mt20 p5">
        <span class="mr10 t1sp">是否收订单费:</span>
        <div class="rsp" style="width: 800px">
          {{
            infos2.notcost_time == null || infos2.notcost_time == 0
              ? "收费"
              : "不收费"
          }}
        </div>
      </div>
      <div
        v-if="infos2.requirements != null"
        class="flex_start flex_items mt20 p5"
      >
        <span class="mr10 t1sp">投诉原因:</span>
        <div class="rsp" style="width: 800px">{{ infos2.requirements }}</div>
      </div>
      <div class="flex_start flex_items mt20 p5">
        <span class="mr10 t1sp">备注信息:</span>
        <div class="rsp" style="width: 800px">{{ infos2.remarks }}</div>
      </div>
      <div class="flex_start flex_items mt20 p5">
        <span class="mr10 t1sp">图片信息:</span>
        <div class="rsp flex_warp" style="width: 800px">
          <div
            v-for="(item, index) in infos2.logo"
            :key="index"
            :data-img="infos2.logo"
            class="mr20 mt10"
            @click="$imgPreview"
          >
            <img class="imgcar" :src="item" alt />
          </div>
        </div>
      </div>
      <div
        v-if="infos2.customers != null"
        class="flex_start flex_items mt20 p5"
      >
        <span class="mr10 t1sp">优惠券(送):</span>
        <div class="rsp mr10" style="width: 200px">
          <el-select
            v-model="youquan"
            placeholder="选择优惠券"
            style="width: 200px"
            :clearable="true"
          >
            <el-option
              v-for="(item, index) in infos2.youhui"
              :key="index"
              :label="item.jine"
              :value="item.youhui_id"
            ></el-option>
          </el-select>
        </div>
        <el-button type="success" size="medium" plain @click="xuanyou"
          >确认赠送</el-button
        >
      </div>
      <div v-else class="flex_start flex_items mt20 p5">
        <span class="mr10 t1sp">发送短信:</span>
        <div class="rsp mr10" style="width: 200px">
          <el-button type="success" size="medium" plain @click="faduan"
            >点击发送</el-button
          >
        </div>
      </div>
      <!-- 店长信息 -->
      <div
        class="flex_between pb10 mt20"
        v-if="infos2.state != 10 && infos2.username"
      >
        <div class="title2 f-left p10 bbe9s">店长信息</div>
      </div>
      <div class="flex_warp p5" v-if="infos2.state != 10 && infos2.username">
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">店长姓名:</span>
            <span class="rsp">{{ infos2.username }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">店长电话:</span>
            <span class="rsp">{{ infos2.mobile }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">所属门店:</span>
            <span class="rsp">{{ infos2.store_name }}</span>
          </div>
        </div>
        <div class="blocks flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">预约上门:</span>
            <span class="rsp">{{ infos2.planned }}</span>
          </div>
          <div class="flex_start m-r-40 flex_items mt20">
            <span class="mr10 t1sp">当前位置:</span>
            <span class="rsp rsp2">{{ nowaddres }}</span>
          </div>
        </div>
      </div>
      <!-- 任务操作记录信息 -->
      <!-- <div>
        操作信息
      </div> -->
      <div
        class="flex_between pb10 mt20"
        v-if="infos2.logRz && infos2.logRz.length > 0"
      >
        <div class="title2 f-left p10 bbe9s">操作信息</div>
      </div>
      <div v-if="infos2.logRz && infos2.logRz.length > 0">
        <!-- v-if="infos2.logRz&&infos2.logRz.length>0" -->
        <el-table :data="infos2.logRz" height="150" border style="width: 100%">
          <el-table-column prop="username" label="姓名" width="180">
          </el-table-column>
          <el-table-column prop="created_at" label="日期" width="180">
          </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- second -->
    <div
      v-if="activeName == 'second'"
      :style="{ display: activeName == 'second' ? 'block' : 'none' }"
    >
      <!-- <div class="flex_between pb10 mt20">
        <div class="title2 f-left p10">任务处理记录</div>
      </div>-->
      <el-table v-if="activeName == 'second'" :data="infos2.through" border>
        <el-table-column prop="th_time" label="时间">
          <template slot-scope="scope">
            <span v-if="activeName == 'second'">{{ scope.row.th_time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="处理人">
          <template slot-scope="scope">
            <div v-if="activeName == 'second'">
              <span v-if="scope.row.role == 1"
                >{{ scope.row.username }}/客服</span
              >
              <span v-else>{{ scope.row.username }}/店长</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="mode" label="方式">
          <template slot-scope="scope">
            <span v-if="activeName == 'second'">{{ scope.row.mode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否接通">
          <template slot-scope="scope">
            <span v-if="scope.row.conversation == 0">是</span>
            <span v-if="scope.row.conversation == 2">否</span>
          </template>
        </el-table-column>
        <el-table-column label="沟通内容">
          <template slot-scope="scope">
            <span v-if="activeName == 'second'">{{ scope.row.remar }}</span>
          </template>
        </el-table-column>
        <el-table-column label="沟通录音">
          <template slot-scope="scope" v-if="activeName == 'second'">
            <div v-if="scope.row.record.length == 0">暂无录音</div>
            <div v-else>
              <audio
                style="width: 100%; outline: none"
                :src="scope.row.record[0].monitorfilename"
                controls="controls"
              ></audio>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="跟进时间">
          <template slot-scope="scope">
            <span v-if="activeName == 'second'">{{ scope.row.th_time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="end_time" label="下次跟进时间"></el-table-column>
        <el-table-column label="图片信息">
          <template slot-scope="scope">
            <div v-if="activeName == 'second'">
              <el-button
                v-if="scope.row.log.length > 0"
                :data-img="scope.row.log"
                type="primary"
                size="small"
                @click="$imgPreview"
                >查看图片</el-button
              >
              <el-button v-else type="info" size="small">暂无图片</el-button>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="处理结果" v-if="activeName=='second'">
          <template slot-scope="scope">
            <span v-if="activeName=='second'">
              <span
                v-if="scope.row.data==null||(scope.row.data.status==1&&scope.row.data!=null)"
              >已处理</span>
              <span v-else>未处理</span>
            </span>
          </template>
        </el-table-column>-->
        <el-table-column style="display: none"></el-table-column>
      </el-table>
      <div>
        <el-button type="primary" size="medium" plain @click="addNote"
          >新增备注</el-button
        >
      </div>
    </div>
    <!-- third -->
    <div
      v-if="activeName == 'third'"
      :style="{ display: activeName == 'third' ? 'block' : 'none' }"
    >
      <div class="flex_between pb10 mt20">
        <div class="title2 f-left p10 bbe9s">基检报价</div>
      </div>
      <el-table v-if="activeName == 'third'" :data="infos2.capital" border>
        <el-table-column label="分类">
          <template slot-scope="scope">
            <span>{{ scope.row.class_a }}</span>
          </template>
        </el-table-column>
        <el-table-column label="名称">
          <template slot-scope="scope">
            <span>{{ scope.row.class_b }}</span>
          </template>
        </el-table-column>
        <el-table-column label="方量">
          <template slot-scope="scope">
            <span>{{ scope.row.square }}/{{ scope.row.company }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格">
          <template slot-scope="scope">
            <span v-if="activeName == 'third'">{{ scope.row.un_Price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="质保">
          <template slot-scope="scope">
            <span v-if="scope.row.zhi == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="crtime" label="基检时间"></el-table-column>
        <el-table-column prop="to_price" label="总金额">
          <template slot-scope="scope">
            <span v-if="activeName == 'third'">{{ scope.row.to_price }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 显示金额 -->
      <div
        v-if="infos2.payment.length != 0"
        class="flex_start m-r-40 flex_items mt20"
      >
        <span style="width: 77px">总金额:</span>
        <span class="rsp">{{ zongjia }}元</span>
      </div>
      <div
        v-if="infos2.give_money != null"
        class="flex_start m-r-40 flex_items mt20"
      >
        <span style="width: 77px">优惠金额:</span>
        <span class="rsp">{{ infos2.give_money }}元</span>
      </div>
      <div
        v-if="infos2.give_money != null"
        class="flex_start m-r-40 flex_items mt20"
      >
        <span style="width: 92px">优惠后总额:</span>
        <span class="rsp" style="color: red">{{ infos2.totalprice }}元</span>
      </div>
      <div
        v-if="infos2.give_b != null"
        class="flex_start m-r-40 flex_items mt20"
      >
        <span style="width: 77px">赠送项目:</span>
        <span class="rsp" style="color: red; width: 90%">{{
          infos2.give_b
        }}</span>
      </div>
      <div
        v-if="infos2.contracType != null"
        class="flex_between pb10 mt20 pb20"
      >
        <div class="title2 f-left p10 bbe9s">合同内容</div>
      </div>
      <div class="mt10 flex_warp" v-if="infos2.contracType == 1">
        <div
          v-for="(item, index) in infos2.contract"
          :key="index"
          :data-img="infos2.contract"
          class="mr20 mt10"
          @click="$imgPreview"
        >
          <img class="imgcar" :src="item" alt />
        </div>
      </div>
      <div class="mt10 flex_warp" v-if="infos2.contracType == 2">
        <img
          :src="infos2.sign.contractpath"
          alt
          style="width: 200px; height: 200px; cursor: pointer"
          @click="tiao"
        />
      </div>
      <div class="mt10 flex_warp" v-if="infos2.contracType == 2">
        <img
          :src="infos2.sign.agency_img"
          alt
          style="width: 200px; height: 200px; cursor: pointer"
          @click="tiao2"
        />
      </div>
      <div
        class="flex_between pb10 mt20 pb20"
        v-if="infos2.capital.length != 0"
      >
        <div class="title2 f-left p10 bbe9s">合同收款</div>
      </div>
      <div class="flex_items mt20 flex_warp" v-if="infos2.capital.length != 0">
        <div class="flex_start m-r-40">
          <span class="mr10 t1sp" style="width: 92px">应收总金额:</span>
          <span class="rsp">{{ infos2.totalprice }}元</span>
        </div>
        <!-- <div class="flex_start m-r-40">
          <span class="mr10 t1sp" style="width: 92px">未收总金额:</span>
          <span class="rsp" style="color: red">{{ nomoney }}元</span>
        </div> -->
      </div>
      <div
        v-for="(item, index1) in infos2.payment"
        :key="index1"
        class="bbe9s pb20"
      >
        <div class="flex_warp">
          <div class="flex_start m-r-40 flex_items mt20">
            <div v-if="item.success == 1" class="mr10 t1sp2">
              已收款金额:<span style="color: #555; margin-left: 20px">
                {{ item.money }}元
                <span class="f14" style="color: red" v-if="item.weixin == 1"
                  >(现金)</span
                >
                <span class="f14" style="color: red" v-else>(微信)</span>
              </span>
            </div>
          </div>
        </div>
        <div class="mt10 flex_warp">
          <div
            v-for="(item, index) in infos2.payment[index1].logos"
            :key="index"
            class="mr20 mt10"
            :data-img="shoukuantu"
            @click="$imgPreview"
          >
            <img class="imgcar" :src="item" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- fourth -->
    <div
      v-if="activeName == 'fourth'"
      :style="{ display: activeName == 'fourth' ? 'block' : 'none' }"
    >
      <!-- <div class="flex_between pb10 mt20">
        <div class="title2 f-left p10 bbes">施工记录</div>
      </div>-->
      <el-table
        v-if="activeName == 'fourth'"
        :data="jindus"
        border
        class="mt20"
      >
        <el-table-column label="所属店铺">
          <template slot-scope="scope">
            <span>{{ scope.row.store_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="施工时间">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at }}</span>
          </template>
        </el-table-column>
        <el-table-column label="师傅姓名">
          <template slot-scope="scope">
            <span v-if="activeName == 'fourth'">{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column label="施工内容">
          <template slot-scope="scope">
            <span>{{ scope.row.title }}:{{ scope.row.describe }}</span>
          </template>
        </el-table-column>
        <el-table-column label="施工图片">
          <template slot-scope="scope">
            <div v-if="activeName == 'fourth'">
              <el-button
                v-if="scope.row.images.length > 0"
                :data-img="scope.row.images"
                type="primary"
                size="small"
                plain
                class="m-t-5 mr5"
                @click="$imgPreview"
                >查看图片</el-button
              >
              <el-button
                v-if="scope.row.videos.length > 0"
                :data-vid="scope.row.videos"
                type="primary"
                size="small"
                plain
                class="mt5"
                style="margin-left: 0"
                @click="$imgPreview"
                >查看视频</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- five -->
    <div
      v-if="activeName == 'five'"
      :style="{ display: activeName == 'five' ? 'block' : 'none' }"
    >
      <!-- <div class="flex_between pb10 mt20">
        <div class="title2 f-left p10 bbes">回访信息</div>
      </div>-->
      <el-table
        v-if="activeName == 'five'"
        :data="infos2.product"
        border
        class="mt20"
      >
        <el-table-column label="备注信息">
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否接通">
          <template slot-scope="scope">
            <span>{{ scope.row.connect }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最满意点">
          <template slot-scope="scope">
            <span>{{ scope.row.best }}</span>
          </template>
        </el-table-column>
        <el-table-column label="不满意点">
          <template slot-scope="scope">
            <span v-if="activeName == 'five'">{{
              scope.row.dissatisfied
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="满意度">
          <template slot-scope="scope">
            <span>{{ scope.row.full }}</span>
          </template>
        </el-table-column>
        <el-table-column label="回访人员">
          <template slot-scope="scope">
            <span>{{ scope.row.people }}</span>
          </template>
        </el-table-column>
        <el-table-column label="回访时间">
          <template slot-scope="scope">
            <span>{{ scope.row.uptime }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增备注弹框 -->
    <el-dialog
      title="订单跟进"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose"
      append-to-body
      :destroy-on-close="true"
      :key="dialogKey"
    >
      <el-form :model="form" label-width="150px" ref="refForm" :rules="rules">
        <el-form-item
          label="订单状态"
          class="labelClass"
          prop="selectedOptions"
        >
          <el-cascader
            change-on-select
            :props="defaultParams"
            :options="options"
            filterable
            v-model="form.selectedOptions"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="跟进时间" class="labelClass" prop="th_time">
          <el-date-picker
            v-model="formData.start_time"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="下次时间" class="labelClass" prop="end_time">
          <el-date-picker
            v-model="form.end_time"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="沟通方式" class="labelClass" prop="mode">
          <el-select
            v-model="form.mode"
            placeholder="选择沟通方式"
            style="width: 138px"
            :clearable="true"
          >
            <el-option
              v-for="item in modes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跟进记录" class="labelClass" prop="remar">
          <el-input type="textarea" v-model="form.remar"></el-input>
        </el-form-item>
        <!-- <el-form-item label="发送短信" class="labelClass">
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="店长"></el-checkbox>
        <el-checkbox label="客户"></el-checkbox>
      </el-checkbox-group>
    </el-form-item> -->
        <el-form-item label="是否转派" class="labelClass">
          <el-radio-group v-model="single1" style="margin-top: 12px">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="   " prop="user" v-if="single1 == '1'">
          <el-select
            v-model="form.user"
            placeholder="请选择转派人"
            style="width: 138px; margin-left: 150px"
            :clearable="true"
          >
            <el-option
              v-for="item in users"
              :key="item.user_id"
              :label="item.username"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button
          style="width: 100%; margin-bottom: 15px"
          size="medium"
          plain
          type="primary"
          @click="addRemark"
          icon="el-icon-document"
          >保存信息</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/util/config";
export default {
  props: ["currentPage3"],
  data() {
    return {
      visiting_time: 1, //期望上门时间
      dispatchType: 1, //派单类型
      rules: {
        selectedOptions: [
          { required: true, message: "请选择订单状态", trigger: "change" },
        ],
        mode: [{ required: true, message: "请选择沟通方式", trigger: "blur" }],
        remar: [
          { required: true, message: "跟进记录不能为空", trigger: "blur" },
        ],
      },
      formData: {
        start_time: "",
      },
      options: [],
      //  selectedOptions: [],
      defaultParams: {
        label: "title",
        value: "title",
        children: "children",
      },
      dialogKey: 1,
      loading: true,
      activeName: "first",
      info: {},
      infos2: {},
      user: [],
      nowaddres: "",
      lists: [],
      waiting: "",
      jilus: [],
      jindus: [],
      shoukuantu: [],
      zongjia: "",
      nomoney: "",
      youquan: "",
      dialogVisible: false,
      single1: 0,
      form: {
        selectedOptions: [],
        mode: "",
        remar: "",
        // th_time: new Date(),
        end_time: " ",
        user: 0,
      },
      //  订单状态
      orderzhuangs: config.orderzhuangs,

      //  沟通方式
      modes: [
        {
          value: "电话",
          label: "电话",
        },
        {
          value: "微信",
          label: "微信",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      // 日期时间选择器配置
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 转派人列表
      users: [],
      // 是否发送短信
      // checkList: ["店长", "客户"],
    };
  },
  mounted() {
    if (this.$route.query.idx) {
      this.$store.commit("setOrder", JSON.parse(this.$route.query.idx));
    }
    this.getData();
    this.getUsers();
    this.getProductType();
    this.getTime();
  },
  //  watch:{this.$store.state.order_id(){getdata()}},
  methods: {
    // 获取当前时间
    getTime() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      let time = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      this.formData.start_time = time;
    },
    // 增加备注按钮
    addNote() {
      this.dialogKey++;
      this.dialogVisible = true;
      this.form = {};
      this.single1 = "0";
      this.selectedOptions = {};
    },
    // 获取级联选择器数据
    getProductType() {
      // 这是从后台获取数据
      this.util.get(this.HOST + "/Common/getRemote").then((res) => {
        if (res.code == 200) {
          // 调用递归方法，去除级联数据后将数据赋值给级联选择器
          this.options = this.getTreeData(res.data);
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    }, // 递归方法
    getTreeData(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续递归调用本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    // 根据订单id获取转派人名单
    getUsers() {
      this.util
        .get(this.HOST + "/Common/user", {
          order_id: this.$store.state.order_id,
          city_id: "",
        })
        .then((res) => {
          if (res.code == 200) {
            this.users = res.data;
            //   let userArr = res.data.map(item => {
            //   return item.username
            // })
            // this.users = userArr
            // //console.log(this.users,"this.users+++++++++")
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 添加备注
    addRemark() {
      // let sms = 0;
      // let sms1 = 0;
      this.$refs["refForm"].validate((valid) => {
        //     if(this.single1 == 0){
        //   this.form.user = ''
        // }
        if (this.single1 == 1) {
          if (!this.form.user) {
            this.$message({
              showClose: true,
              message: "请选择转派人!",
              type: "error",
              duration: 1200,
              offset: 90,
            });
            return false;
          }
        }
        // for (let i = 0; i < this.checkList.length; i++) {
        //   if (this.checkList[i] != "店长") {
        //     sms = 1;
        //   }
        //   if (this.checkList[i] != "客户") {
        //     sms1 = 1;
        //   }
        // }
        if (valid) {
          this.util
            .get(this.HOST + "/Through/getList", {
              order_id: this.$store.state.order_id,
              mode: this.form.mode,
              remar: this.form.remar,
              end_time: this.form.end_time,
              // single:this.form.single,
              single: this.single1,
              user_id: this.form.user,
              th_time: this.formData.start_time,
              status: this.form.selectedOptions[0],
              status1: this.form.selectedOptions[1],
              status2: this.form.selectedOptions[2],
              // sms1: sms1,
              // sms: sms,
            })
            .then((res) => {
              if (res.code == 200) {
                // this.$loading().close();
                this.$message({
                  showClose: true,
                  message: "添加备注成功!",
                  type: "success",
                  duration: 1200,
                  offset: 90,
                });
                setTimeout(() => {
                  this.$store.commit("setOrder", null);
                  this.$store.commit("setMessage", false);
                  this.$store.commit("settask", 1);
                  this.$emit("getDataAgain", this.currentPage3);
                  this.$emit("update:currentPage3", this.currentPage3);
                }, 100);
                this.dialogVisible = false;
                this.getData();
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "error",
                  duration: 1200,
                  offset: 90,
                });
                setTimeout(() => {
                  this.$store.commit("setOrder", null);
                  this.$store.commit("setMessage", false);
                  this.$store.commit("settask", 1);
                }, 100);
                this.dialogVisible = false;
                this.getData();
              }
            });
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    getData() {
      this.util
        .get(this.HOST + "/Order/info", {
          // order_id: 16069,
          order_id: this.$store.state.order_id,
          Colloquialism: 4,
        })
        .then((res) => {
          let qixin = "无时间限制";
          if (res.code == 200) {
            this.visiting_time = res.data.visiting_time;
            this.dispatchType = res.data.dispatchType;
            for (let i = 0; i < res.data.product.length; i++) {
              res.data.product[i].uptime = this.util.timestampToTime(
                res.data.product[i].uptime
              );
            }
            if (res.data.youhui.length > 0) {
              for (let i = 0; i < res.data.youhui.length; i++) {
                if (res.data.youhui[i].lalst_time != 0) {
                  res.data.youhui[i].lalst_time = this.util.timestampToTime(
                    res.data.youhui[i].lalst_time
                  );
                  qixin = res.data.youhui[i].lalst_time + "过期";
                }
                res.data.youhui[i].jine =
                  res.data.youhui[i].jine + "元优惠券" + "——" + qixin;
              }
            }
            if (res.data.logRz.length > 0) {
              for (let i = 0; i < res.data.logRz.length; i++) {
                res.data.logRz[i].created_at = this.util.timestampToTime(
                  res.data.logRz[i].created_at
                );
              }
            }
            if (res.data.wechat == 0) {
              res.data.wechat = false;
            } else {
              res.data.wechat = true;
            }
            this.infos2 = res.data;

            if (res.data.tui_jian != 0) {
              res.data.content = res.data.content1;
            }

            this.jilus = res.data.through;
            this.lists = res.data.capital;
            let zongjia = 0;
            let nomoney = 0;
            for (let h = 0; h < res.data.capital.length; h++) {
              zongjia += Number(res.data.capital[h].to_price);
            }
            this.zongjia = zongjia;
            if (res.data.payment.length > 0) {
              for (let i = 0; i < res.data.payment.length; i++) {
                nomoney += Number(res.data.payment[i].money);
                for (let j = 0; j < res.data.payment[i].logos.length; j++) {
                  this.shoukuantu.push(res.data.payment[i].logos[j]);
                }
              }
              this.nomoney = res.data.totalprice - nomoney;
            }
            // if(res.data.username){
            this.init();
            // }
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 2000,
              offset: 90,
            });
            this.loading = false;
            this.dialogVisible = false;
          }
        });
    },
    init() {
      // 打包前
      // this.util
      //   .get(this.HOST3, {
      //     location: this.user.lat + "," + this.user.lng,
      //     key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG"
      //   })
      //   .then(res => {
      //     this.nowaddres = res.result.formatted_addresses.recommend;
      //     this.$loading().close();
      //   });
      // 打包后
      if (
        this.infos2.userLat == "" ||
        this.infos2.userLat == "0.0" ||
        this.infos2.userLat == null
      ) {
        this.jindu();
        return false;
      }
      this.$jsonp(this.HOST3, {
        location:
          Number(this.infos2.userLat) + "," + Number(this.infos2.userLng),
        key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
        output: "jsonp",
      }).then((res) => {
        this.nowaddres = res.result.formatted_addresses.recommend;
        this.jindu();
      });
    },
    jindu() {
      this.util
        .get(this.HOST + "/Mast/List", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.jindus = res.data;
            for (let i = 0; i < this.jindus.length; i++) {
              this.jindus[i].images = [];
              this.jindus[i].videos = [];
              this.jindus[i].created_at = this.util.timestampToTime(
                this.jindus[i].created_at
              );
              if (this.jindus[i].resource.length > 0) {
                for (let j = 0; j < this.jindus[i].resource.length; j++) {
                  if (
                    this.jindus[i].resource[j].mime_type.split("/")[0] ==
                    "image"
                  ) {
                    this.jindus[i].images.push(
                      this.jindus[i].resource[j].domain +
                        this.jindus[i].resource[j].path
                    );
                  } else {
                    this.jindus[i].videos.push(
                      this.jindus[i].resource[j].domain +
                        this.jindus[i].resource[j].path
                    );
                  }
                }
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    xuanyou() {
      this.util
        .get(this.HOST + "/Order/Welfare", {
          customers_id: this.infos2.customers.customers_id,
          youhui_id: this.youquan,
          order_id: this.infos2.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "赠送成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    faduan() {
      this.util
        .get(this.HOST + "/Order/sendMessage", {
          telephone: this.infos2.telephone,
          city_id: this.infos2.city_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "发送成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 添加微信操作
    changewechat(order_id, wechat) {
      let wechat2 = 0;
      if (wechat == true) {
        wechat2 = 1;
      }
      this.util
        .get(this.HOST + "/Order/SelectorSwitch", {
          order_id: order_id,
          wechat: wechat2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "操作成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
    },
    tiao() {
      window.open(this.infos2.sign.contractpath, "_blank");
    },
    tiao2() {
      window.open(this.infos2.sign.agency_img, "_blank");
    },
  },
};
</script>
<style scoped>
.orderdetails {
  /* width: 1024px; */
  margin: 0 auto;
  padding: 10px;
  background: white;
}
.el-tabs__item {
  font-size: 18px;
}
.el-tab-pane {
  display: none;
}
.title2 {
  /* font-size: 16px;
  background: #3f51b5;
  color: white;
  width: 120px;
  cursor: pointer; */
  font-weight: 400;
  color: #1f2f3d;
  font-size: 18px;
  background: none;
  width: 93%;
}
.t1sp {
  color: #3f51b5;
  width: 75px;
}
.t1sp2 {
  color: #3f51b5;
}
.rsp {
  width: 160px;
}
.imgcar {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
#allmap {
  /* width: 100%;
  height: 100%; */
  width: 100%;
  min-height: 800px;
  overflow: hidden;
  margin: 0;
  font-family: "微软雅黑";
}
.blocks {
  width: 100%;
}
.rsp2 {
  width: 230px;
}
/* .el-form-item__label{
  text-align: left!important;
} */
.labelClass .el-form-item__label {
  color: black !important;
}
</style>
